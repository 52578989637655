<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-collection k-standards-home-wrapper">
	<iframe v-if="iframe_rendered" name="satchel_tab_iframe" class="k-standards-home-iframe" :src="iframe_src"></iframe>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
	},
	data() { return {
		iframe_rendered: false,

		establish_connection_tries: 0,
		max_establish_connection_tries: 10,
		establish_connection_retry_timeout: 500,
		// total amount of time, in seconds, we will try to establish a connection: (max_establish_connection_tries * establish_connection_retry_timeout) / 1000  [e.g. 60 * 500 / 1000 == 30 seconds]
		connection_established: false,

		// pm_verbose: false,
		pm_verbose: true,
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		satchel_origin() { return this.site_config.satchel_origin },
		iframe_src() {
			let url = vapp.cglt_sso_url(this.satchel_origin, '?cureum_standards_tabbed')
			console.log(url)
			return url
			// return this.site_config.satchel_origin + '?cureum_standards_tabbed'
			// return 'http://localhost:6051?cureum_standards_tabbed'
		},
		satchel_domain() {
			return this.satchel_origin.match(/^(?:https?:\/\/)?([^\/]+)/i)[1]
		},
	},
	watch: {
		'$route.path': {immediate: true, handler(val) {
			// when the route changes to standards (showing this component)...
			if (this.$route.path.indexOf('standards') == 1) {
				// hide google translate from cureum (it will be available from within satchel)
				vapp.toggle_google_translate_btn('hide')

				// if we haven't yet shown the iframe...
				if (!this.iframe_rendered) {
					// set iframe_rendered so that we show satchel
					this.iframe_rendered = true

					this.tab_pm_initialize()
					// // then on nexttick start establishing the connection
					// this.$nextTick(x=>{
					// 	this.tab_pm_initialize()
					// })
				}

			// and when the user leaves this route...
			} else {
				// re-show google translate
				vapp.toggle_google_translate_btn('show')

				// leave satchel showing in case the user comes back later
			}
		}},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		// implement a simplified version of the postMessage protocol from SatchelInline.
		// here, we establish the connection from Cureum to Satchel, but then we never send any additional commands from Cureum to Satchel
		// what we do is receive commands from Satchel -- for starters, a command to "show resources aligned to a given standard"

		// this is the initialize fn for window A that embeds/opens window B
		// here, we establish the eventListener for postMessages, then send a message to window B establishing the connection.
		tab_pm_initialize() {
			// set up eventListener for postMessages
			window.addEventListener('message', (event) => {
				// event.source is the window that sent the message (the window that included the iframe)
				// event.origin is the origin that sent it; make sure we trust it
				// event.data is what was sent

				// Do we trust the sender of this message?
				if (event.origin !== this.satchel_origin) {
					console.log(`   PMX HOST    !-- message to cureum from untrusted satchel origin: trusted ${this.satchel_origin} / event ${event.origin}`, event.data)
					return
				}

				// messages should all have the form {msg: 'xxx', data: ...}
				if (typeof(event.data) != 'object' || empty(event.data.msg)) {
					console.log('   PMX HOST    !-- bad message received', event.data)
					return
				}
				let msg = event.data.msg
				let data = event.data.data

				if (msg == 'received_establish_connection') {
					// if we already finished loading, return
					if (this.connection_established) {
						if (this.pm_verbose) console.log('   PMX HOST    <-- received_establish_connection after connection already established')
						return
					}

					if (this.pm_verbose) console.log('   PMX HOST    <-- CONNECTION ESTABLISHED WITH SATCHEL')
					this.connection_established = true
				
				///////////////////////////////////////////
				// HERE ARE THE MESSAGES THAT SATCHEL MAY SEND BACK TO US HERE
				} else if (msg == 'framework_loaded') {
					if (this.pm_verbose) console.log('   PMX HOST    <-- framework_loaded: ' + JSON.stringify(data))
					// satchel sends this when a new framework is loaded, but we're not currently doing anything with this here

				} else if (msg == 'current_location') {
					if (this.pm_verbose) console.log('   PMX HOST    <-- current_location: ' + JSON.stringify(data))
					// this would be returned if we sent a 'get_current_location' msg, but we don't have any plans to do that as of now

				} else if (msg == 'show_resource_search') {
					if (this.pm_verbose) console.log('   PMX HOST    <-- show_resource_search: ' + JSON.stringify(data))
					
					// TODO: execute the search

				// more messages here...
				} else {
					if (this.pm_verbose) console.log('   PMX HOST    !-- received unprocessed message: ', msg, data)
				}
			}, false);

			// start trying to establish the connection (don't show a loader here; the user doesn't have to wait to start browsing in Satchel)
			setTimeout(x=>{ this.pm_establish_connection() }, 100)
		},

		pm_establish_connection() {
			// window B may not be ready to receive messages right away, so keep sending this message until it succeeds, or until we exceed max_establish_connection_tries
			if (!this.connection_established) {
				if (this.establish_connection_tries > this.max_establish_connection_tries) {
					console.log('   PMX HOST    !-- giving up on establishing connection')
					// if we give up here, throw a console error but don't warn the user
					console.error('The connection to Satchel could not be opened.')
					return
				}
				++this.establish_connection_tries
				if (this.pm_verbose) console.log('   PMX HOST    --> queuing establish_connection message (' + this.establish_connection_tries + ')')
				this.pm_send('establish_connection')

				setTimeout(x=>{ this.pm_establish_connection() }, this.establish_connection_retry_timeout)
			}
		},

		pm_send(msg, data, resolve, reject) {
			if (this.pm_verbose) console.log('   PMX HOST    --> pm_send: ' + msg)

			// queue a message to be sent; second param specifies what the origin of the target window must be for the event to be dispatched
			try {
				if (msg == 'establish_connection' && this.establish_connection_tries == 1) {
					// in console, note that we may get an error message here
					console.log('   PMX HOST    --> NOTE: “Failed to execute \'postMessage\' on \'DOMWindow\'” message may occur on first `establish_connection` message try')
				}
				window.satchel_tab_iframe.postMessage({msg: msg, data: data}, this.satchel_origin)
				if (resolve) resolve('ok')

			} catch(e) {
				console.log('   PMX HOST    !-- pm_send error caught: ', e)
				if (reject) reject(e)
			}
		},
	}
}
</script>

<style lang="scss">
.k-standards-home-wrapper {
	position:relative;
	border-top:1px solid #fff;

	.k-standards-home-iframe {
		position:absolute;
		z-index:4;
		left:0;
		top:0;
		width:100vw; 
		// height:100vh;
		// height:calc(100vh - 52px);
		// height:calc(100vh - 48px);
		height:calc(100vh - 62px);
		// height:calc(100vh - 88px);
		background-color:#fff;
		border:0;
	}
}
</style>
